import { createEvent, sendToNative } from '@eaphone/js-bridge';
import { Auth } from '@eaphone/storage';

export function loginHook(to, from, next) {
  if (to.meta.auth && !Auth.token) {
    sendToNative({
      type: 'token',
      eventId: createEvent(),
    })
      .then((token) => {
        Auth.token = token;
        next();
      })
      .catch(() => {
        next({ name: 'error-sdk' });
      });
  } else {
    next(); // 不做任何处理
  }
}

export function usePageConfig() {}
