import 'vant/es/dialog/style';
import 'vant/es/toast/style';
import 'vant/es/cell/style';

import { createApp } from 'vue';

import '@eaphone/polyfill';
import '@eaphone/debugger';
import '@eaphone/style/style.scss';
import { loginHook } from '@eaphone/logic';
import { SentryInit } from '@eaphone/sentry';
import { store } from '@eaphone/store';

import pkg from '../package.json';

import { router } from './pages/router.js';

import App from './app.vue';

import('./style.css');

const app = createApp(App);

router.beforeEach(loginHook.bind(router));

SentryInit({
  app,
  router,
  version: pkg.version,
});

app.use(store).use(router);

app.mount('#root');
