export const route = {
  component: () => import('./page.vue'),
  path: '/hotel/volume',
  name: 'hotel-volume',
  meta: {
    title: '音量控制',
    auth: true,
    needPhone: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
      baseAuth: true,
    },
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'volume-o',
  to: {
    name: route.name,
  },
};
