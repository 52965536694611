import { isIOS } from '@eaphone/env';

window.bridge = {
  nativeApp: {
    postMessage(data, origin) {
      window.postMessage(data, origin);
    },
  },
};

function getAppMessagePort(name) {
  return isIOS ? window.webkit?.messageHandlers?.[name] : window[name];
}

const nativeApp = getAppMessagePort('nativeApp'); // 兼容旧版

export function sendMessage(data) {
  if (nativeApp && typeof nativeApp.postMessage === 'function') {
    nativeApp.postMessage(JSON.stringify(data));
  } else {
    throw new TypeError('nativeApp');
  }
}
