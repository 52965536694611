import { captureException } from '@eaphone/sentry';

import { sendMessage } from './lib.js';

export function createEvent() {
  return `webview-${window.btoa(new Date().toISOString())}`;
}

function binder(callback, eventId) {
  function handler({ data: { eventId: currentId, payload } }) {
    if (eventId) {
      if (eventId === currentId) {
        callback(payload);
        window.removeEventListener('message', handler, false);
      }
    } else {
      callback(payload);
    }
  }

  window.addEventListener('message', handler, false);
}

export function sendToNative(result) {
  return new Promise((resolve, reject) => {
    try {
      sendMessage(result);

      if (result.eventId) {
        binder(resolve, result.eventId);
      } else {
        resolve();
      }
    } catch (error) {
      console.error(error);
      reject(error);
      captureException(error, {
        tags: {
          scene: 'js-bridge',
        },
        contexts: {
          data: result,
        },
      });
    }
  });
}
